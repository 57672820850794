import $ from 'jquery';
import moment from "moment";

const WebflowFilter = function () {
    let options = {'webflowDataHost': null};
    let webflowData = {blogPosts: [], successStories: [], collectionMetas: { postType: [], serviceType: [], industryType: [] }};
    const filterResultsContainer = $('.filter-results-container');
    const loadingZone = $('.loading-zone');
    const dataSet = loadingZone.data('dataset');
    const baseItem = $('.filter-item-placeholder').clone();
    let activeFilters = [];

    async function download(url) {
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return await response.json();
    }

    function downloadData() {
        download(options.webflowDataHost + 'collection_metas.json').then(response => {
            webflowData.collectionMetas = response;
        });

        if ( $( 'div[data-dataset="blogPosts"]' ).length > 0 ){
            download( options.webflowDataHost + 'post.json').then(response => {
                webflowData.blogPosts = response;
                buildBlogPage();
            });
        }

        if ( $( 'div[data-dataset="successStories"]' ).length > 0 ) {
            download(options.webflowDataHost + 'success-story.json').then(response => {
                webflowData.successStories = response;
                buildSuccessStoryPage();
            });
        }
    }

    function buildItemLink(item) {
        return document.location + '/' + item.slug;
    }

    function getPostMeta(metaIds, data){
        let metas = [];

        if (Array.isArray(metaIds)){
            metaIds.forEach( metaId => {
                metas = metas.concat( data.filter( meta => meta._id === metaId ) );
            } );
        } else {
            metas = data.filter( meta => meta._id === metaIds );
        }

        return metas.map( meta => meta.name );
    }

    function getPostMetaId(currentMetaName) {
        let metaId = '';
        for (let metaDataType in webflowData.collectionMetas){
            if ( webflowData.collectionMetas.hasOwnProperty( metaDataType ) ){
                let tmp = webflowData.collectionMetas[metaDataType].filter( data => {
                    return data.name === currentMetaName;
                } ).map( data => {
                    metaId = data._id;

                    return metaId;
                } )[0];
            }
        }

        return metaId;
    }


    function renderCollectionItemMetadata(selector, item, data) {
        item.find( selector ).text( getPostMeta( data, webflowData.collectionMetas.industryType ).join(', ') );

        return item;
    }

    function buildItem(data = { name: '', category: '', slug: '', 'featured-image': '', 'published-on': '', 'post-type': '' }) {
        let item = baseItem.clone();
        item.removeClass('filter-item-placeholder');
        item.find('.replace-name').text(data.name);
        item.find('.replace-date').text(moment(data['published-on']).format("MMMM Do, YYYY"));
        item.find('.replace-link').attr('href', buildItemLink(data));

        if ( data['featured-image'] !== undefined ) item.find('.replace-image').attr('src', data['featured-image'].url);
        if ( data['main-image'] !== undefined ) item.find('.replace-image').attr('src', data['main-image'].url);
        if ( data['post-summary'] !== undefined ) item.find('.replace-summary').text(data['post-summary']);
        if ( data.category !== undefined ) item.find('.replace-category').text(data.category);

        if ( data['post-type-5'] !== undefined ) item = renderCollectionItemMetadata('.replace-type', item, data['post-type-5']);
        if ( data['service-type'] !== undefined ) item = renderCollectionItemMetadata('.replace-service', item, data['service-type']);
        if ( data['industry-type-2'] !== undefined ) item = renderCollectionItemMetadata('.replace-industry', item, data['industry-type-2']);
        if ( data['industry-tags'] !== undefined ) item = renderCollectionItemMetadata('.replace-industry', item, data['industry-tags']);

        return item;
    }

    function buildPage() {
        switch ( dataSet ){
            case 'blogPosts': buildBlogPage(); break;
            case 'successStories': buildSuccessStoryPage(); break;
        }
    }

    function renderItem(itemData) {
        filterResultsContainer.append( buildItem(itemData) );
    }

    function buildSuccessStoryPage() {
        webflowData.successStories.forEach((itemData)=>{
            renderItem(itemData);
        });
    }

    function buildBlogPage() {
        webflowData.blogPosts.forEach((itemData)=>{
            renderItem(itemData);
        });
    }

    function getCriteriaFromElement(criteria) {
        return criteria.text();
    }

    function getActiveFilterType(type) {
        let activeFilter = [];

        $('.filter-' + type + '.active').each(function (i, e) {
            activeFilter.push( $(e).text() );
        });

        return activeFilter;
    }

    function enableFilter( criteria, type ) {
        criteria.addClass('active');
        activeFilters.push( {criteria: getCriteriaFromElement(criteria), type: type} );
        toggleFilter();
    }

    function disableFilter( criteria, type ) {
        criteria.removeClass('active');
        activeFilters = activeFilters.filter((filter)=>{
            return (!(filter.type === type && filter.criteria === getCriteriaFromElement(criteria)));
        });
        toggleFilter();
    }

    function filterCategories() {
        $('.filter-category').on('click', function(){
            if ( $(this).hasClass('active') ){
                disableFilter( $(this), 'category' );
            } else {
                enableFilter( $(this), 'category' );
            }
        });
    }

    function filterIndustries() {
        $('.filter-industry').on('click', function(){
            if ( $(this).hasClass('active') ){
                disableFilter( $(this), 'industry-type-2' );
                disableFilter( $(this), 'industry-tags' );
            } else {
                enableFilter( $(this), 'industry-type-2' );
                enableFilter( $(this), 'industry-tags' );
            }
        });
    }

    function filterPostTypes() {
        $('.filter-type').on('click', function(){
            if ( $(this).hasClass('active') ){
                disableFilter( $(this), 'post-type-5' );
            } else {
                enableFilter( $(this), 'post-type-5' );
            }
        });
    }

    function filterServices() {
        $('.filter-service').on('click', function(){
            if ( $(this).hasClass('active') ){
                disableFilter( $(this), 'service-type' );
            } else {
                enableFilter( $(this), 'service-type' );
            }
        });
    }

    function toggleFilter(){
        if ( webflowData[dataSet] !== undefined ) {
            filterResultsContainer.empty();
            applyFilters();
        }
    }

    function applyFilters() {
        let activeItem = [];
        if ( activeFilters.length > 0 ){
            activeFilters.map((currentFilter)=>{
                webflowData[dataSet].map((itemData)=>{
                    if ( ! activeItem.includes( itemData._id ) ) {
                        if ( itemData[currentFilter.type] !== undefined ){
                            if (itemData[currentFilter.type].includes( getPostMetaId(currentFilter.criteria) )) {
                                renderItem(itemData);
                                activeItem.push(itemData._id);
                            }
                        }
                    }
                });
            })
        } else {
            buildPage();
        }
    }

    function init( { webflowDataHost }) {
        options = arguments[0];
        downloadData();
        filterCategories();
        filterIndustries();
        filterPostTypes();
        filterServices();
    }

    return {
        init
    };
}();

export default WebflowFilter;
