import $ from 'jquery';
import Cookies from 'js-cookie';

const Pages = function () {
    function pageHeaderScrollInit() {
        const $win = $(window);
        const $pageHeader = $('#page-header');

        $win.scroll(function () {
            if ($win.scrollTop() < 400){
                $pageHeader.removeClass('bg-black');
                $pageHeader.addClass('bg-transparent');
            } else {
                $pageHeader.removeClass('bg-transparent');
                $pageHeader.addClass('bg-black');
            }
        });
    }
    function collapseExpandContentInit() {
        const $collapseExpand = $('.collapse-expand');

        $collapseExpand.addClass('collapsed');
        $('.collapse-expand .read-more-text').hide();
        $('.collapse-expand .read-less').on('click', function(e){
            e.preventDefault();
            $(this).parent('.collapse-expand').toggleClass('expanded').toggleClass('collapsed');
            $(this).siblings('.read-more-text').slideToggle();
        });

        $('.collapse-expand .read-more').on('click', function(e){
            e.preventDefault();
            $(this).parent('.collapse-expand').toggleClass('expanded').toggleClass('collapsed');
            $(this).siblings('.read-more-text').slideToggle();
        });
    }

    function shareButtonsInit() {
        $('#share-linkedin, #share-facebook').each(function(){
            $(this).attr('href', $(this).attr('href') + document.location);
        });

        $('#share-twitter').each(function(){
            $(this).attr('href', $(this).attr('href') + document.title + ' ' + document.location);
        });
    }

    function newsletterFormInit() {
        $('#newsletter-form').on('submit', function(e){
            e.preventDefault();

            const form = this;

            const handleNewsletterForm = {};
            handleNewsletterForm.time = new Date().getTime();

            $(form).attr('target', 'iframe'+handleNewsletterForm.time);

            const iframe = $('<iframe data-time="'+handleNewsletterForm.time+'" style="display:none;" name="iframe'+handleNewsletterForm.time+'"></iframe>');
            $( "body" ).append(iframe);

            form.submit();
            form.reset();
            $(form).siblings('.w-form-done').slideDown(function(){
                let currentMSG = $(this);
                setTimeout(function(){
                    currentMSG.slideUp();
                }, 5000);
            });
        });
    }

    function getUrlVars() {
        const vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });
        return vars;
    }

    function getUrlParam(parameter, defaultValue = null){
        let urlParameter = defaultValue;
        if(window.location.href.indexOf(parameter) > -1){
            urlParameter = getUrlVars()[parameter];
        }
        return urlParameter;
    }

    function initBurgerMenu() {
        $('.burger-menu').on('click', function () {
            $('.menu').toggleClass('shown');
        });
    }

    function linkedinLigthbox() {
        if( !getUrlParam('utm_source') || getUrlParam('utm_source') !== 'Linkedin'){
            $('#ligthbox-podcast').remove();
        }
    }

    function showLigthbox(idLigthbox) {
        console.log('Showing? ' + idLigthbox);
        if ( Cookies.get(idLigthbox) === undefined ){
            console.log('Yes, showing...')
            $('.ligthbox').fadeIn();
            Cookies.set(idLigthbox, true);
        } else {
            console.log('No, not showing');
        }
    }

    function hideLigthbox() {
        $('.ligthbox').fadeOut();
    }

    function init() {
        pageHeaderScrollInit();
        shareButtonsInit();
        newsletterFormInit();
        collapseExpandContentInit();
        initBurgerMenu();
        linkedinLigthbox();

        $('body').on('click', '.ligthbox-close', function () {
            hideLigthbox();
        });
    }

    return {
        init,
        showLigthbox,
        hideLigthbox
    }
}();

export default Pages;
