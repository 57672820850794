import $ from 'jquery';
import WebflowFilter from "./webflow_filter";
import Pages from './pages';

let options = { "webflowDataHost": "https://webflow-cms.gerentllc.com/" };

try {
    options = require('../../options');
} catch (ex) {
    console.info('No options file');
}

const App = function () {
    function init() {
        Pages.init();
        WebflowFilter.init({ webflowDataHost: options.webflowDataHost });
    }

    return {
        init,
        Pages
    }
}();

App.init();

window.GerentApp = App;

